import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Typography from '@mui/material/Typography';

const Service = () => {
  const theme = useTheme();

  return (
    <Main>
      <Container>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700
          }}
        >
          Privacy Policy
        </Typography>
        <Typography
          variant="body1"
					component="pre"
          align={'left'}
          data-aos={'fade-up'}
          gutterBottom
					sx={{
						whiteSpace: 'break-spaces'
					}}
        >
          Protecting your private information is our priority. This Statement of Privacy applies to Flomesh.io, www.flomesh.io, Flomesh, and Flomesh, ltd. and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Flomesh include Flomesh, ltd. Flomesh.io, and www.flomesh.io. The Flomesh website is a Company site. By using the Flomesh website, you consent to the data practices described in this statement.

                <h2>Collection of your Personal Information</h2>

                In order to better provide you with products and services offered on our Site, Flomesh may collect personally identifiable information, such as your:

                <ul>
                <li> First and Last Name </li>
                <li> E-mail Address </li>
                <li> Employer </li>
                <li> Job Title </li>
                </ul>

                Flomesh may also collect anonymous demographic information, which is not unique to you, such as your:

                <ul><li>Gender</li></ul>

                Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Flomesh’s public message boards, this information may be collected and used by others.

                We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstake or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.

                <h2> Use of your Personal Information </h2>

                Flomesh collects and uses your personal information to operate its website(s) and deliver the services you have requested.

                Flomesh may also use your personally identifiable information to inform you of other products or services available from Flomesh and its affiliates.

                <h2> Sharing Information with Third Parties </h2>

                Flomesh does not sell, rent or lease its customer lists to third parties.

                Flomesh may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Flomesh, and they are required to maintain the confidentiality of your information.

                Flomesh may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Flomesh or the site; (b) protect and defend the rights or property of Flomesh; and/or (c) act under exigent circumstances to protect the personal safety of users of Flomesh, or the public.

                <h2> Tracking User Behavior </h2>

                Flomesh may keep track of the websites and pages our users visit within Flomesh, in order to determine what Flomesh services are the most popular. This data is used to deliver customized content and advertising within Flomesh to customers whose behavior indicates that they are interested in a particular subject area.

                Flomesh may use tools like Google Analytics, HotJar, and Mixpannel or similar tools to track user interest in specific topics related to internet technologies and current or future product offerings.

                <h2> Automatically Collected Information </h2>

                Information about your computer hardware and software may be automatically collected by Flomesh. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Flomesh website.

                <h2> Use of Cookies </h2>

                The Flomesh website may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.

                One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Flomesh pages, or register with Flomesh site or services, a cookie helps Flomesh to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Flomesh website, the information you previously provided can be retrieved, so you can easily use the Flomesh features that you customized.

                You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Flomesh services or websites you visit.

                <h2> Links </h2>

                This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.

                <h2> Security of your Personal Information </h2>

                Flomesh secures your personal information from unauthorized access, use, or disclosure. Flomesh uses the following methods for this purpose:

                <ul><li>SSL Protocol</li></ul>

                When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.

                We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.

                <h2> External Data Storage Sites </h2>

                We may store your data on servers provided by third-party hosting vendors with whom we have contracted.

                <h2> Changes to this Statement </h2>

                Flomesh reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.

                <h2> Contact Information </h2>

                Flomesh welcomes your questions or comments regarding this Statement of Privacy. If you believe that Flomesh has not adhered to this Statement, please contact Flomesh at:

                <br/>Room 1508 , 15/F Witty Commercial Building,<br/>
                1A-1L Tung Choi Street , Mongkok, Kowloon, Hong Kong<br/>

                <br/>Email Address:<br/>
                privacy@flomesh.io<br/>

                <br/>Telephone number:<br/>
                +86 155 6666 7703<br/>
                <br/>
                Effective as of December 17, 2021
        </Typography>
      </Container>
    </Main>
  );
};

export default Service;
